<div class="modal-body">
  <div class="mat-dialog-title">Asset Library</div>
  <div class="mat-dialog-subtext">Select the files you wish to add to the playbook.</div>
  <!-- search does not appear to be working... <div class="search">
    <div>Search for an asset by keyword or use the filter below.</div>
    <div class="from-group">
      <input type="text" class="form-control" [(ngModel)]="search.name">
      <i class="fa fa-search"></i>
    </div>
  </div>-->
  <div class="library">
    <div class="library-item" *ngFor="let asset of assets">
      <div class="library-item__header" [ngStyle]="{'background-image': 'url(' + (asset.thumb || 'assets/images/noimage.png' ) + ')'}">
        <div class="checkbox checkbox-asset">
          <input type="checkbox" [(ngModel)]="asset.selected" id="{{assets.indexOf(asset)}}">
          <label for="{{assets.indexOf(asset)}}"></label>
        </div>
      </div>
      <div class="library-item__footer">{{asset.name}}</div>
    </div>
  </div>
  <div class="text-right" style="margin:0 15px;">
    <button class="cq-button cq-button--inline cq-button--cancel" md-dialog-close>Cancel</button>
    <button class="cq-button cq-button--inline" (click)="select()">Select</button>
  </div>
</div>