<div class="block-title" *ngIf="totalCounts.kits">
  <h3><span>{{totalCounts.kits}}</span> {{totalCounts.kits === 1 ? 'Kit' : 'Kits' }}</h3>
</div>
<div class="grid" *ngIf="totalCounts.kits">
  <div class="grid-item" *ngFor="let kit of items.kits">
    <app-kit-entity [kit]="kit" [user]="authUser.current"></app-kit-entity>
  </div>
</div>
<div class="more-wrap" *ngIf="totalCounts.kits && totalCounts.kits > limit.kits">
  <div class="btn-group"  >
    <button class="btn btn-primary" title="Show previous Kits" (click)="backward('kits')" [disabled]="!skip.kits">
      <
    </button>
    <button class="btn btn-primary" title="Show next Kits" (click)="forward('kits')" [disabled]="totalCounts.kits <= ( limit.kits + skip.kits )">
      >
    </button>
  </div>
</div>

<div class="divider"></div>

<div class="block-title" *ngIf="totalCounts.assets">
  <h3><span>{{totalCounts.assets}}</span> {{items.assets.length === 1 ? 'Asset' : 'Assets'}}</h3>
</div>
<div class="grid" *ngIf="totalCounts.assets">
  <div class="grid-item " *ngFor="let asset of items.assets">
    <app-asset-entity [asset]="asset"></app-asset-entity>
  </div>

</div>

<div class="more-wrap" *ngIf="totalCounts.assets && totalCounts.assets > limit.assets">
  <div class="btn-group"  >
    <button class="btn btn-primary" title="Show previous Assets" (click)="backward('assets')" [disabled]="!skip.assets">
      <
    </button>
    <button class="btn btn-primary" title="Show next Assets" (click)="forward('assets')" [disabled]="totalCounts.assets <= ( limit.assets + skip.assets )">
      >
    </button>
  </div>
</div>

<div class="divider"></div>

<div class="block-title" *ngIf="totalCounts.playbooks">
  <h3><span>{{totalCounts.playbooks}}</span> {{totalCounts.playbook === 1 ? 'Playbook' : 'Playbooks'}}</h3>
</div>

<div class="grid" *ngIf="totalCounts.playbooks">
  <div class="grid-item " *ngFor="let playbook of items.playbooks">
    <app-playbook-entity [playbook]="playbook"></app-playbook-entity>
  </div>
</div>

<div class="more-wrap" *ngIf="totalCounts.playbooks && totalCounts.playbooks > limit.playbooks">
  <div class="btn-group"  >
    <button class="btn btn-primary" title="Show previous playbooks" (click)="backward('playbooks')" [disabled]="!skip.playbooks">
      <
    </button>
    <button class="btn btn-primary" title="Show next playbooks" (click)="forward('playbooks')" [disabled]="totalCounts.playbooks <= ( limit.playbooks + skip.playbooks )">
      >
    </button>
  </div>
</div>
