<div class="plan">
  <div class="wrapper">
    <div class="main-container" [style.background-color]="plan.metadata.bck">
      <div class="title">{{plan.name}}</div>
      <div class="price"><span>&#36;</span>{{plan.amount / 100}}</div>
      <div class="interval">billed per {{plan.interval}}</div>
      <button class="btn btn-primary" [style.background-color]="plan.metadata.btn" *ngIf="plan.id !== subscription?.plan.id" (click)="subscribe(plan)"
        [disabled]="plan.inProg">
            Select
          </button>
      <button class="btn btn-primary" [style.background-color]="plan.metadata.btn" *ngIf="plan.id == subscription?.plan.id">Current</button>
    </div>
    <div class="benefits" [style.background-color]="plan.metadata.ftr">
      <div class="benefit-item">
        <i class="material-icons">check</i>
        <span>{{plan.metadata.playbooks}} playbooks</span>
      </div>
      <div class="benefit-item">
        <i class="material-icons">check</i>
        <span>{{plan.metadata.users}} users</span>
      </div>
      <div class="benefit-item" *ngFor="let ben of plan.miscBen">
        <i class="material-icons">check</i>
        <span>{{ben}}</span>
      </div>
    </div>
  </div>
</div>