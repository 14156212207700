<div class="modal-body">
  <div class="mat-dialog-title secondary">You've hit the {{item}} limit.</div>
  <div class="mat-dialog-subtext">
    <span>If you would like to add an additional {{item}} please consider upgrading from the {{planName}} plan.</span>
  </div>
  <div class="text-center">
    <button class="cq-button cq-button--inline cq-button--cancel" [md-dialog-close]>Cancel</button>
    <button class="cq-button cq-button--inline" [md-dialog-close]="1">Change plan</button>
  </div>
</div>
