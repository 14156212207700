<div class="row" style="border-bottom: solid 1px #dedede; width: 100vw;">
  <div class="search-top-bar col-sm-11 col-sm-offset-1">
    <div class="title">
      <span>{{user.curCompany.name}} Team</span>
      {{data?.length}} {{ data?.length === 1 ? 'member' : 'members' }}
    </div>
    <div class="sort pull-right">
      <label>Sort By</label>
      <div class="select">
        <i class="fa fa-angle-down"></i>
        <select [(ngModel)]="sort" (change)="sortBy()" class="form-control">
          <option value="fname">FirstName</option>
          <option value="lname">LastName</option>
        </select>
      </div>
    </div>
  </div>
</div>

<div class="row team-container">
  <div class="alphabet col-sm-1 col-xs-1 col-md-1 col-lg-1">
    <ul class="list-unstyled">
      <li>
        <button class="btn btn-abc" [disabled]="!show" (click)="enable('')">
          ALL
        </button>
      </li>
      <li *ngFor="let item of team | keys ">
        <button class="btn btn-abc" [disabled]="!item.value.length" (click)="enable(item.key)">
          {{item.key.toUpperCase()}}
        </button>
      </li>
    </ul>
  </div>
  <div class="user-content col-10 col-offset-1">
    <div class="search-block" *ngFor="let item of team | keys">
      <div class="title" *ngIf="item?.value.length && ( ! show || show === item?.key )">
        {{ item.key.toUpperCase() }}
      </div>
      <div class="assets-list" *ngIf="item?.value.length && ( ! show || show === item?.key )">
        <app-user-entity [user]="member" *ngFor="let member of item?.value"></app-user-entity>
      </div>
    </div>
  </div>
</div>
