<md-dialog-content style="width:1158px; max-width: 100vw; padding-top: 28px;" *ngIf="asset?.canShow">
	<div class="asset-priview-wrap container-fluid">
		<div class="row">
			<div class="asset-preview col-xs-12 col-sm-12 col-md-8 col-lg-8">
				<div class="title">
					<span md-dialog-title>{{asset?.name}}</span>
					<div class="pull-right">
						<i class="material-icons" (click)="cancel()">close</i>
					</div>
				</div>
				<div class="subtitle">
					<br/> Created on {{asset?.created | date:'MMM dd, yyyy'}} by {{asset?.creator.fname}}&nbsp;{{asset?.creator.lname}}
					<button class="follow-btn" *ngIf="!isYou" (click)="followCreator(asset?.creator)">{{isFollowed ? 'Unfollow':'Follow'}}</button>
				</div>
        <p>{{asset?.description}}</p>
        <a id="assetLink" [href]="asset?.path" target="_blank" role="button"><i class="fas fa-external-link-square-alt"></i> Open in new window</a>
        <app-file-viewer [id]="asset?._id" [path]="asset?.path" [filetype]="asset.filetype" *ngIf="!asset?.isblocked || asset.isblocked == null"></app-file-viewer>
			</div>
			<div class="asset-tags col-xs-12 col-sm-12 col-md-4 col-lg-4">
				<div class="icon-block">
					<app-like [type]="'asset'" [likesCount]="asset?.likes.length" [isLiked]="asset?.likes.indexOf(authUser.current?._id) >= 0"
					 [id]="asset?._id">
					</app-like>
					<a class="text-center add-to-kit" (click)="addToKit()">
						<i class="fa fa-plus"></i>
					  </a>
					<a download role="button" [attr.href]="sanitizer.bypassSecurityTrustResourceUrl(asset?.path)"><i class="fa fa-download"></i></a>
				</div>
				<div class="block">
					<label>Tags</label>
					<tag-input [ngModel]="asset.tags" [onAdding]="onAdding" [onRemoving]="onRemoving">
						<tag-input-dropdown [autocompleteObservable]="getTags">
						</tag-input-dropdown>
					</tag-input>
				</div>
				<div class="block">
					<label>Comments ({{totalCount}})</label>
					<div class="comment-item" *ngFor="let comment of comments">
						<div class="info">
							<div class="date">
								<span>{{comment.creator.fname + ' ' + comment.creator.lname}}</span> - {{ comment.created | amTimeAgo }}
							</div>
							<app-like [id]="comment._id" [type]="'comment'" [isLiked]="comment.likes.indexOf(authUser.current?._id) >=0" [likesCount]="comment.likes.length"></app-like>
						</div>
						<p [innerHtml]="getCommentBody(comment)"></p>
					</div>
					<div class="more-wrap" *ngIf="totalCount && totalCount > limit">
						<div class="btn-group">
							<button class="btn btn-primary" title="Show previous comments" (click)="backward()" *ngIf="skip">
								<
							</button>
							<button class="btn btn-primary" title="Show next comments" (click)="forward()" *ngIf="totalCount > ( limit + skip )">
								>
							</button>
						</div>
					</div>
					<div class="divider" *ngIf="!comments?.length"></div>
					<label>{{ autoSuggest ? 'Type email and select user' : 'Add Comment'}}</label>
					<form class="comment-form">
						<div class="form-group">
							<textarea (keyup)="keyup($event)" class="form-control" [(ngModel)]="comment.body" name="note">
							</textarea>
							<div class="member-candidades">
								<span *ngFor="let user of mention" (click)="onSelectMentioner(user)">
								{{user.email}}
								</span>
							</div>
						</div>
						<div class="form-group text-right">
							<button class="btn btn-primary" [disabled]="!comment.body||isRequesting||autoSuggest" (click)="addComment()">Submit</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</md-dialog-content>
