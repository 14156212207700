<div class="modal-body">
	<div class="title">Create Announcement</div>
	<p>Notify your users of something important!</p>
	<div class="form-group">
		<textarea class="form-control control-dashed" [(ngModel)]="message"></textarea>
	</div>
	<div class="form-group btns-block">
		<button class="btn btn-default" md-dialog-close>Cancel</button>
		<button class="btn btn-primary" (click)="submit()" [disabled]="!message">Apply</button>
	</div>
</div>