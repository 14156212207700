<div class="app-item">

  <div *ngIf="asset.thumb != '' && asset.thumb != null && asset.thumb != 'undefined'" class="grid-image" [ngStyle]="{'background-image': 'url(' + (asset.thumb) + ')'}" (click)="previewAsset(asset)">
    <div class="grid-item-details">
      <div class="name" [innerHtml]="smartName(asset.name)"></div>
      <div class="date">Created: {{asset.created | date:'MMM dd, yyyy'}}</div>
    </div>
    <div class="grid-item-desc">{{asset.description}}</div>
  </div>

  <div *ngIf="asset.thumb == '' || asset.thumb == null || asset.thumb == 'undefined'" class="grid-image" [style.background-color]="fileIconColor()" (click)="previewAsset(asset)">
    <div [classList]="fileIcon()" ></div>
    <div class="grid-item-details">
      <div class="name" [innerHtml]="smartName(asset.name)"></div>
      <div class="date">Created: {{asset.created | date:'MMM dd, yyyy'}}</div>
    </div>
    <div class="grid-item-desc">{{asset.description}}</div>
  </div>

  <div class="grid-item-footer">
    <app-like [type]="'asset'" [likesCount]="asset.likes.length" [isLiked]="asset.likes.indexOf(authUser.current?._id) >= 0"
      [id]="asset._id">
    </app-like>
    <app-comment [id]="asset._id" [count]="asset.commentsCount" (click)="previewAsset(asset)"></app-comment>
    <a class="text-center add-to-kit" title="{{inKit ? 'Remove from kit this asset' : 'Add to kit this asset'}}" (click)="inKit ? removeFromKit(asset._id) : addToKit(asset._id)">
      <i *ngIf="isMy||!inKit" class="fa fa-{{inKit ? 'minus' : 'plus' }}"></i>
    </a>
    <a download role="button" [attr.href]="asset?.path"><i class="fa fa-download"></i></a>
    <!-- <a href="#"><i class="material-icons">share</i></a> -->

    <a role="button" [mdMenuTriggerFor]="mdMenu"><i class="material-icons">more_vert</i></a>
  </div>
  <md-menu #mdMenu="mdMenu">
    <button md-menu-item (click)="delete()" *ngIf=" this.authUser.current && (this.asset.creator._id == this.authUser?.current._id || authUser.current?.role =='owner' || authUser.current?.role == 'admin')">Delete</button>
    <button md-menu-item (click)="downloadBtn.click()"><a style="color: rgba(0,0,0,.87);" #downloadBtn download [attr.href]="asset.path">Download</a></button>
  </md-menu>
  <div class="action-bar" *ngIf="showActionBar">
    <ul class="list-unstyled">
      <li>
        <a (click)="delete()" *ngIf="asset.creator._id === authUser.current?._id  || authUser.current?.role =='owner' || authUser.current?.role == 'admin'">Delete</a>
      </li>
      <li><a download [attr.href]="asset.path">Share to FaceBook</a></li>
      <li><a download [attr.href]="asset.path">Share to LinkeIn</a></li>
      <li><a download [attr.href]="asset.path">Share via Email</a></li>
    </ul>
  </div>
</div>
