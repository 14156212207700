<div class="modal-body">
  <div class="title">Add Assets</div>
  <p *ngIf="!downloading">What would you like to do?</p>
  <p *ngIf="downloading">Please wait while your file is uploading</p>
  <div class="btns-block" *ngIf="!downloading">
    <button class="btn btn-primary" [md-dialog-close]="1">Upload Files from your machine</button>
    <button class="btn btn-primary" [md-dialog-close]="2" *ngIf="isPlaybook">Add Files From Asset Library</button>
    <button class="btn btn-primary" [md-dialog-close]="3">Link To A URL</button>
    <div id="cloud">
      <button class="btn btn-primary" (click)="google()" [disabled]="gettingToken">Choose files from Google Drive</button>
      <button class="btn btn-primary" (click)="dropbox()">Choose files from Dropbox</button>
    </div>
    <button class="btn btn-default" [md-dialog-close]="false">Cancel</button>
  </div>
  <div class="" *ngIf="downloading">
    <img src="assets/images/Spinner.gif" />
  </div>
</div>
