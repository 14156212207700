<div class="tool-bar">
	<div class="container">
		<ul class="nav">
			<li *ngFor="let item of dispTabs; let i = index; " [attr.class]="_activeTab === i ? 'active' : ''">
                <a (click)="setActiveTab(i)"
				   [routerLink]="['./', item]">
					{{item.charAt(0).toUpperCase() + item.slice(1)}}&nbsp;{{ totalCounts.hasOwnProperty(item) ? totalCounts[item] : '' }}
				</a>
            </li>
		</ul>
	</div>
</div>
<div class="user-profile-wrap container-fluid">
	<div class="row">
		<div class="user-sidebar col-sm-12 col-xs-12 col-lg-2 col-md-3">
			<div class="sidebar-card user-card">
				<div class="user-info-item text-center">
					<div class="user-photo" [ngStyle]="{'background-image': 'url(' + ( user.avatar || 'assets/images/noavatar.png') + ')'}"></div>
					<div class="name">{{user.fname}} {{user.lname}} </div>
					<div class="position">{{user.occupation}}</div>
					<p>{{user.regions}}</p>
					<div class="position">
						{{user.role}}
					</div>
					<button [disabled]="!canFollow" class="btn btn-{{ user.isFollowed ? 'default' : 'primary' }}" *ngIf="!isMe" (click)="follow()">
						<i class="material-icons">{{ user.isFollowed ? 'person' : 'person_add' }}</i>
						{{ user.isFollowed ? 'Unfollow' : 'Follow' }}
					</button>
                    <p *ngIf="isMe">
						<a title="Edit Profile" class="btn btn-primary" [routerLink]="['/settings']">Edit Profile</a>
					</p>

				</div>
				<div class="user-info-item" *ngIf="user.description">
					<h4>About</h4>
					<p>{{user.description}}</p>
				</div>
			</div>
			<div class="sidebar-card">
				<ul class="activity" >
					<li class="activity-title">{{user.fname}}'s Activity</li>
					<li *ngFor="let activity of activities" (click)="activityClick(activity)">
						<div class="time">{{ activity.created | amTimeAgo }}</div>
						{{getActivityMessage(activity)}}
					</li>

				</ul>
			</div>
		</div>
		<div class="user-items col-sm-12 col-xs-12 col-md-9 col-lg-10" >
            <router-outlet></router-outlet>
		</div>
	</div>
</div>
