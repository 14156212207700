<div class="text-center">
  <h4 *ngIf="message">{{message}}</h4>
  <div class="text-center">
    <a class="btn btn-default" (click)="cancel()">
      Cancel
    </a>
    <a class="btn btn-primary" (click)="confirm()">
      Confirm
    </a>
  </div>
</div>
