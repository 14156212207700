<div class="tool-bar">
    <div class="container">
        <div class="row">
            <div class="col-xs-12 col-sm-3">
                <h2>{{kit?.name}}
                </h2>
                <p *ngIf="kit?.followers">{{ kit?.followers.length }} follower{{kit?.followers.length === 1 ? '' : 's'}}</p>
            </div>
            <div class="col-xs-12 col-sm-5" *ngIf="isMy">
                <label>Description:</label>
                <input [disabled]="!isMy" (change)="updateDescription()" type="text" name="kit.description" class="form-control" [(ngModel)]="kit.description">
            </div>
            <div class="col-xs-12 col-sm-5" *ngIf="!isMy">
                <label>Description:</label>
                <p [innerHtml]="kit.description">
            </div>
            <div class="col-xs-12 col-sm-4">
                <ul class="list-unstyled btn-list">
                    <li>
                        <a (click)="confirmDelete()" class="btn btn-info" *ngIf="isMy">
                            Delete
                        </a>
                    </li>
                    <li>
                        <a class="btn btn-info" (click)="clone()" *ngIf="isMy">
                            Save a copy
                        </a>
                    </li>
                    <li>
                        <a class="btn btn-primary" (click)="sync()" *ngIf="isMy">
                            Sync
                        </a>
                    </li>
                    <li>
                        <a (click)="follow()" class="btn btn-primary" *ngIf="!isMy">
                            {{ isFollowed ? 'Unfollow' : 'Follow'}}
                        </a>
                    </li>
                </ul>

            </div>
        </div>
    </div>
</div>
<div class="kit-wrap">
    <div class="container">
        <div class="row">
            <div class="sidebar-new col-xs-12 col-sm-3">
                <div class="sidebar-card">
                    <h4>Collaborators</h4>
                    <div>
                        <span class="alert alert-success members" *ngFor="let user of kit?.collaborators">
                        <img [src]="user.avatar" width="50" height="50" />
                        {{user.fname + '&nbsp;' + user.lname}}
                        <a *ngIf="isMy" (click)="removeMember(user._id)">
                            <i class="fa fa-close"></i>
                        </a>
                    </span>
                        <span *ngIf="isMy">
                            <a (click)="toggleUserAtoComplete()" class="btn btn-info btn-xs">
                            {{ showUserAC ? 'Add' : '...'}}
                            </a>
                        </span>
                    </div>
                    <div class="user-auto-complete {{showUserAC? '' : 'hidden'}}">
                        <input [(ngModel)]="memberEmail" (change)="getUsers()" (keyup)="getUsers()" class="form-control" name="users">
                        <div class="member-candidades" *ngIf="users.length && memberEmail">
                            <span *ngFor="let member of users" (click)="onSelectMember(member)">
                               {{member.email}}
                             </span>
                        </div>
                    </div>

                </div>
                <div class="sidebar-card">
                    <div>
                        <h4>Tags</h4>
                        <div class="" *ngIf="!isMy">
                            <span class="alert alert-success tags" *ngFor="let tag of kit?.tags">{{tag}}</span>
                        </div>
                        <tag-input [ngModel]="kit?.tags" [onAdding]="onAdding" [onRemoving]="onRemoving" *ngIf="isMy">
                            <tag-input-dropdown [autocompleteObservable]="getTags">
                            </tag-input-dropdown>
                        </tag-input>
                    </div>
                </div>
                <div class="sidebar-card">
                    <h4>Visibility</h4>
                    <label class="pull-left">Public <input [disabled]="!isMy" [checked]="kit?.publish "  (change)="updateKitPublish(true)" type="radio" [value]="true" name="kit.publish" ></label>
                    <label class="pull-right">Private <input  [disabled]="!isMy"  [checked]="!kit?.publish" (change)="updateKitPublish(false)" type="radio" [value]="false" name="kit.publish" ></label>
                </div>
                <div class="sidebar-card">
                    <h4>Note</h4>
                    <div class="kit-note {{editNoteMode ? 'hidden' : ''}}" [innerHtml]="_note">

                    </div>
                    <textarea (keyup)="keyup($event)" class="form-control {{editNoteMode ? '':'hidden' }}" [(ngModel)]="kit.note" name="note"></textarea>
                    <div class="member-candidades">
                        <span *ngFor="let user of mention" (click)="onSelectMentioner(user)">
                          {{user.email}}
                        </span>
                    </div>
                    <a class="btn btn-info btn-xs" *ngIf="isMy" (click)="editNote()">
                       {{editNoteMode ? 'Save':'Edit'}}
                    </a>
                </div>
            </div>
            <div class="kit-content col-xs-12 col-sm-9">
                <div class="block-title">
                    <h3>Assets</h3>
                </div>
                <div class="block-sub-title">
                    <span>{{totalCounts.assets}} {{totalCounts.assets === 1 ? 'Asset' : 'Assets'}} in this kit</span>
                    <a *ngIf="isMy" class="btn btn-primary btn-xs" (click)="createAsset()">Add new</a>
                </div>
                <div class="grid">
                    <div class="grid-item " *ngFor="let asset of kit?.assets">
                        <app-asset-entity [asset]="asset"></app-asset-entity>
                    </div>
                </div>
                <div class="more-wrap" *ngIf="totalCounts.assets > query.limit.assets">
                    <div class="btn-group">
                        <button class="btn btn-primary" title="Show previous assets" (click)="backward('assets')" [disabled]="!query.skip.assets">
                            <
                        </button>
                        <button class="btn btn-primary" title="Show next assets" (click)="forward('assets')" [disabled]="totalCounts.assets <= ( query.skip.assets + query.limit.assets )">
                            >
                        </button>
                    </div>
                </div>
                <div class="divider"></div>
                <div class="block-title">
                    <h3>Playbooks</h3>
                </div>
                <div class="block-sub-title">
                    <span>{{totalCounts.playbooks}} {{totalCounts.playbooks === 1 ? 'Playbook' : 'Playbooks'}} in this kit</span>
                    <a *ngIf="isMy" class="btn btn-primary btn-xs" (click)="addPlaybook()">Add new</a>
                </div>
                <div class="grid">
                    <div class="grid-item " *ngFor="let playbook of kit?.playbooks">
                        <app-playbook-entity [playbook]="playbook"></app-playbook-entity>
                    </div>
                </div>
                <div class="more-wrap" *ngIf="totalCounts.playbooks > query.limit.playbooks">
                    <div class="btn-group">
                        <button class="btn btn-primary" title="Show previous playbooks" (click)="backward('playbooks')" [disabled]="!query.skip.playbooks">
                            <
                        </button>
                        <button class="btn btn-primary" title="Show next playbooks" (click)="forward('playbooks')" [disabled]="totalCounts.playbooks <= ( query.skip.playbooks + query.limit.playbooks )">
                            >
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>