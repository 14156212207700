<div class="modal-body">
  <div class="title">Playbook Published</div>
  <p>Your playbook has been published and can be accessed via the following URL:</p>
  <form>
    <div class="form-group">
      <input type="text" class="form-control control-dashed control-link" readonly value="{{link}}">
    </div>
  </form>
  <div class="text-center">
    <button class="btn btn-primary" (click)="done()">ok</button>
  </div>
</div>