<div class="announcements">
  <div class="title">Announcements</div>
  <div class="announcements-item" *ngFor="let an of announcments">
    <span>{{an.created | date:'MMM dd'}}</span> 
    <div class="announcement-body" [innerHTML]="an.message | linky"></div>
    <a href="#" *ngIf="an.creator == user.current?._id ||  user.current?.role == 'owner'" (click)="removeAnn(an)"><i class="fa fa-minus"></i></a>
  </div>
  <span *ngIf="announcments?.length == 0" class="ui-message">No announcements yet. Use the "+" icon to create your first announcement.</span>
  <div class="announcements-item empty" *ngIf="user.current?.role != 'participant'">
    <a (click)="createAnnouncment()"><i class="fa fa-plus"></i></a>
  </div>
</div>