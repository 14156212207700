<div class="modal-body">
  <div *ngIf="kits?.length">
    <h3>Choose kit:</h3>
    <div class="row" *ngFor="let kit of kits">
      <div class="col-xs-8"> <b>{{kit.name}}</b> ~ {{kit.created | date:'MMM dd yyyy' }}</div>
      <div class="col-xs-4 text-right"><a class="btn btn-primary" (click)="addToKit(kit._id)">Add</a> </div>
    </div>
    <div class="row">
      <div class="more-wrap modal-mode" *ngIf="totalCount && totalCount > limit">
        <div class="btn-group"  >
          <button class="btn btn-primary" title="Show previous kits" (click)="backward()" [disabled]="!skip">
            <
          </button>
          <button class="btn btn-primary" title="Show next kits" (click)="forward()" [disabled]="totalCount <=  limit + skip ">
            >
          </button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!kits?.length">
    <div class="row">
      <div class="col-xs-12">
        <button class="btn btn-primary" (click)="create()">Create Kit</button>
      </div>
    </div>
  </div>
</div>

