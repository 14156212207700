<div class="block-title" >
  <h3><span>{{totalCounts.kits}}</span> {{totalCounts.kits === 1 ? 'Kit' : 'Kits' }}</h3>
  <div>
    <a [routerLink]="['/user/', id, 'kits']" *ngIf="totalCounts.kits > items.kits.length">Show All</a>
    <button *ngIf="isMe" (click)="createKit()" class="btn btn-primary btn-xs"><span style="font-weight: bold">+</span>&nbsp;Add new kit</button>
  </div>
</div>
<div class="grid" *ngIf="totalCounts.kits">
  <div class="grid-item" *ngFor="let kit of items.kits">
    <app-kit-entity [kit]="kit" [user]="authUser.current"></app-kit-entity>
  </div>

</div>
<div class="block-title" >
  <h3><span>{{totalCounts.assets}}</span> {{items.assets.length === 1 ? 'Asset' : 'Assets'}}</h3>
  <div>
    <a [routerLink]="['/user/', id, 'assets']" *ngIf="totalCounts.assets > items.assets.length">Show All</a>
    <button *ngIf="isMe"  (click)="createAsset()" class="btn btn-primary btn-xs"><span style="font-weight: bold">+</span>&nbsp;Add new asset</button>
  </div>
</div>
<div class="grid" *ngIf="totalCounts.assets">
  <div class="grid-item " *ngFor="let asset of items.assets">
    <app-asset-entity [asset]="asset"></app-asset-entity>
  </div>
</div>
<div class="block-title" >
  <h3><span>{{totalCounts.playbooks}}</span> {{totalCounts.playbook === 1 ? 'Playbook' : 'Playbooks'}}</h3>
  <div>
    <a [routerLink]="['/user/', id, 'playbooks']" *ngIf="totalCounts.playbooks > items.playbooks.length">Show All</a>
    <button *ngIf="isMe"  (click)="createPlaybook()" class="btn btn-primary btn-xs"><span style="font-weight: bold">+</span>&nbsp;Add new playbook</button>
  </div>
</div>
<div class="grid" *ngIf="totalCounts.playbooks">
  <div class="grid-item " *ngFor="let playbook of items.playbooks">
   <app-playbook-entity [playbook]="playbook"></app-playbook-entity>
  </div>
</div>
