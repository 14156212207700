<div class="form-column-header">
  <label class="column-label">Step Content</label>
</div>
<div class="form-group form-column">
  <div class="form-items playbook-content">
    <div class="texteditor" #textEditor>
      <ckeditor [(ngModel)]="playbook.selectedStep.content" [config]="editorConfig"></ckeditor>
    </div>
    <div>
      <!-- Nav tabs -->
      <ul class="nav nav-tabs" role="tablist">
        <li role="presentation" [ngClass]="{'active': contentMode == 1}"><a href="javascript:void(0)" (click)="contentMode = 1">Assets</a></li>
        <li role="presentation" [ngClass]="{'active': contentMode == 2}"><a href="javascript:void(0)" (click)="contentMode = 2">Questions To Ask</a></li>
        <li role="presentation" [ngClass]="{'active': contentMode == 3}"><a href="javascript:void(0)" (click)="contentMode = 3">Tasks</a></li>
      </ul>
      <!-- Tab panes -->
      <div class="tab-content">
        <div class="assets-wrap" *ngIf="contentMode == 1">
          <button class="btn btn-info btn-small" (click)="addAsset()">+</button>
          <div class="asset-item-wrap" *ngFor="let asset of playbook.selectedStep.assets">
            <button (click)="playbook.removeAsset(asset)">-</button>
            <a href="javascript:void(0)" class="file-asset">
              <span>{{asset.name}}</span> {{asset.description}}
            </a>
          </div>
        </div>
        <div class="tab-content" *ngIf="contentMode == 2">
          <button class="btn btn-info btn-small" (click)="playbook.addQ()">+</button>
          <div class="q-item" *ngFor="let q of playbook.selectedStep.questions">
            <input type="text1" class="form-control control-dashed" [(ngModel)]="q.value">
            <button class="btn btn-info btn-small" (click)="playbook.removeQ(q)">-</button>
          </div>
        </div>
        <div class="tab-content" *ngIf="contentMode == 3">
          <button class="btn btn-info btn-small" (click)="playbook.addTask()">+</button>
          <div class="task-item" *ngFor="let task of playbook.selectedStep.tasks">
            <input type="checkbox" [(ngModel)]="task.checked" id="checkBox-{{playbook.selectedStep.tasks.indexOf(task)}}">
            <label for="checkBox-{{playbook.selectedStep.tasks.indexOf(task)}}"></label>
            <input type="text" class="form-control control-dashed" [(ngModel)]="task.value">
            <button class="btn btn-info btn-small" (click)="playbook.removeTask(task)">-</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>