<div class="app-item" >
  <div class="playbook-body" [routerLink]="['/playbook', 'view', playbook._id]" [style.background]="playbook.color">
    <!-- <h4 [innerHtml]="smartName(playbook.name)"></h4> -->
    <h4 style="word-wrap:breakword">{{shortName(playbook.name)}}</h4>
    <div class="date">By {{playbook.creator.fname}} {{playbook.creator.lname}}  <span>{{ playbook.created | date:'MMM dd yyyy' }}</span></div>
    <!--<p>{{playbook.description}}</p>-->
  </div>
  <div class="grid-item-footer">
    <div>
      <app-like
              [type]="'playbook'"
              [likesCount]="playbook.likes.length"
              [isLiked]="playbook.likes.indexOf(authUser.current?._id) >=0"
              [id]="playbook._id">
      </app-like>
      <a title="{{inKit ? 'Remove from kit  this playbook' : 'Add to kit this playbook'}}" class="text-center add-to-kit"  *ngIf="hasKit" (click)="inKit ? removeFromKit(playbook._id) : addToKit(playbook._id)">
        <i class="fa fa-{{inKit ? 'minus' : 'plus'}}"></i>
      </a>

      <a href="#" style="margin-left:15px;"><i class="material-icons" (click)="$event.preventDefault()">share</i></a>
    </div>

    <a (click)="delete()" *ngIf="playbook.creator._id == authUser.current._id || authUser.current.role =='owner' || authUser.current.role == 'admin'"><i class="material-icons" style="font-size:20px;">delete</i></a>
    <!--<a *ngIf="canEdit(playbook)" role="button" (click)="toggleActionBar()"><i class="material-icons">more_vert</i></a>-->
  </div>
  <div class="grid-item-action" *ngIf="playbook.creator._id == authUser.current._id || authUser.current.role =='owner' || authUser.current.role == 'admin'">
    <a (click)="edit()">Edit</a>
  </div>
  <!--<div class="action-bar" *ngIf="showActionBar">
    <ul class="list-unstyled">
      <li>
        <a (click)="edit()">Edit</a>
      </li>
      <li>
        <a (click)="delete()" *ngIf="playbook.creator._id == authUser.current._id || authUser.current.role =='owner' || authUser.current.role == 'admin'">Delete</a>
      </li>
    </ul>
  </div>-->
</div>
