<div class="form-column-header">
    <label class="column-label">Stage Steps</label>
    <button class="btn btn-info btn-small" (click)="playbook.addStep()">+</button>
</div>
<div class="form-group  form-column">

    <div class="form-items" style="background-color:#f0f0f7; border-right:solid 1px #dedede;">
        <div class="steps-item" [ngClass]="{'active': playbook.selectedStep == step}" *ngFor="let step of playbook.selectedStage.steps; let i = index">
            <input type="text" class="form-control" [(ngModel)]="step.value" (click)="playbook.selectedStep = step" placeholder="Next Step" />
            <div class="btn-grey-wrap">
                <button class="btn btn-info btn-small" (click)="playbook.removeStep(step)">-</button>
                <div class="actionmenu" [ngClass]="{'active': playbook.selectedStep.doReorder}"  *ngIf="playbook.selectedStage.steps.length > 1">
                    <div class="actionmenu__label" (click)="playbook.toggleReorderStep()">&uarr;&darr;</div>
                    <ul class="actionmenu__list">
                        <li class="actionmenu__item actionmenu__item--up" *ngIf="i !== 0" (click)="playbook.moveStepUp(step)">Move Up</li>
                        <li class="actionmenu__item actionmenu__item--dn" *ngIf="i !== (playbook.selectedStage.steps.length -1)" (click)="playbook.moveStepDown(step)">Move Down</li>
                        <li class="actionmenu__item actionmenu__item--cancel" (click)="playbook.closeReorderSteps()">Cancel</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
