<div class="container">
  <div class="row sidebar-container">
    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-2 sidebar-wrapper">
      <div class="sidebar">
        <div class="title">
          <span>{{settingsMap[getActive()]}}</span> Settings
        </div>
        <ul class="nav">
          <li [ngClass]="{'active': getActive() == 0}">
            <a [routerLink]="['profile']">Profile</a>
          </li>
          <li [ngClass]="{'active': getActive() == 1}" *ngIf="authUser.current?.role === 'owner' || authUser.current?.role === 'admin'">
            <a [routerLink]="['company']">Organization</a>
          </li>
          <li [ngClass]="{'active': getActive() == 2}" *ngIf="authUser.current?.role === 'owner'">
            <a [routerLink]="['billing']">Plan / Billing</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-xs-12 col-sm-8 col-md-9 col-lg-10">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>