<div class="container-fluid">
    <h4>You can save your assets to GoogleDrive or Dropbox</h4>
    <div class="row" *ngIf="!chooseGD">
        <div class="text-center" >
            <a title="Save to Dropbox" class="btn btn-primary" (click)="saveToDropBox()"><i class="fa fa-dropbox"></i></a>
            <a title="Save to Google Drive" class="btn btn-primary" (click)="saveToGoogleDrive()"><i class="fa fa-google"></i></a>
        </div>
    </div>
    <div class="row" id="container" *ngIf="chooseGD">
        <div class="row" *ngFor="let asset of googleDriveOptions | keys">
            <div class="col-xs-6">
                {{asset.value.filename}}
            </div>
            <div class="col-xs-6">
                <div class="g-savetodrive"
                     [attr.data-src] = asset.value.src
                     [attr.data-filename] = asset.value.filename
                     [attr.data-sitename] = asset.value.sitename
                >
                </div>
            </div>
        </div>
    </div>
</div>