<div class="modal-body">
  <div class="title">{{kit._id ? 'Update' : 'Create new'}} Kit</div>
  <form>
    <div class="form-group col-sm-12">
      <label>Kit Name:</label>
      <input (focus)="hideError()" required class="form-control {{showTitleError ? 'has-error' : ''}}" type="text" name="kit.name" [(ngModel)]="kit.name">
      <span class="alert alert-error" *ngIf="showTitleError">Kit name is required field</span>
    </div>
    <div class="form-group col-sm-12">
      <label >Kit Description:</label>
      <textarea class="form-control" [(ngModel)]="kit.description" name="kit.description">
      </textarea>
    </div>
    <div class="form-group col-sm-12">
      <button class="btn btn-primary" (click)='save()'>{{kit._id ? 'Update' : 'Save'}}</button>
    </div>
  </form>
</div>
