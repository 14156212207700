<div class="col-lg-7">
  <h3>Organization</h3>
  <form name="form" (ngSubmit)="f.form.valid && update()" #f="ngForm" novalidate *ngIf="company">
    <div class="row">
      <div class="col-sm-4 ">
        <div class="form-group">
          <label>logo</label>
          <div class="file file-img" [hidden]="company.previewLogo">
            <input type="file" id="pic" #fileInput (change)="fileSelected($event)">
            <label for="pic"><span>+</span></label>
          </div>
          <div class="img-wrap" [hidden]="!company.logo" (click)="fileInput.click()">
            <img [src]="company.previewLogo" alt="">
          </div>
        </div>
      </div>
      <div class="col-sm-8">
        <div class="form-group" [ngClass]="{'has-error': f.submitted && !companyName.valid}">
          <label>Company / Organization Name</label>
          <input type="text" class="form-control" [(ngModel)]="company.name" name="companyName" required #companyName="ngModel" (keyup)="nameChanged()">
          <p class="help-block" *ngIf="f.submitted && companyName.errors?.required">This is required</p>
          <p class="help-block" *ngIf="f.submitted && !urlAvailable">This name is already taken</p>
        </div>
        <div class="form-group">
          <label>CloseQuickly URL</label>
          <input type="text" class="form-control" [(ngModel)]="urlPreview" name="companyUrl" [disabled]="true">
        </div>
        <div class="form-group" [ngClass]="{'has-error': f.submitted && !adminEmail.valid}">
          <label>Admin Email</label>
          <input type="email" class="form-control" [(ngModel)]="company.adminEmail" name="adminEmail" #adminEmail="ngModel" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$">
          <p class="help-block" *ngIf="f.submitted && adminEmail.errors?.pattern">Not a valid email</p>
        </div>
      </div>
    </div>
    <div class="text-right">
      <button class="btn btn-primary" type="submit" [disabled]="loading">Save Changes</button>
    </div>
  </form>
  <h3>Invite new member</h3>
  <div class="users-form" style="max-width: 900px;">
    <div class="user-item">
      <div class="form-group" style="width:100%" [ngClass]="{'has-error': error}">
        <input type="email" class="form-control control-filled" placeholder="Enter email of whom to invite" [(ngModel)]="invite.email"
          name="email" (keypress)="this.error = null">
        <span class="help-block" *ngIf="error">{{error}}</span>
      </div>
      <div class="select filled" style="height:100%">
        <select class="form-control control-filled" name="role" [(ngModel)]="invite.role" name="role">
          <option *ngFor="let role of permissions" [value]="role.val">{{role.text}}</option>
        </select>
        <i class="fa fa-angle-down"></i>
      </div>
      <button class="btn btn-success" (click)="inviteUser()" [disabled]="!invite.email">Invite</button>
    </div>
  </div>
  <h3 *ngIf="invites.length > 0">Pending invites</h3>
  <div class="users-form" style="max-width: 900px;">
    <div class="from-froup user-item" *ngFor="let inv of invites">
      <input type="text" class="form-control control-filled" [value]="inv.receiverEmail" readonly>
      <div class="select filled">
        <select class="form-control control-filled" [(ngModel)]="inv.role">
          <option *ngFor="let role of permissions" [value]="role.val">{{role.text}}</option>
        </select>
        <i class="fa fa-angle-down"></i>
      </div>
      <button class="btn btn-success" (click)="removeInvite(inv)">Remove</button>
    </div>
  </div>
  <h3>Team</h3>
  <div class="users-form" style="max-width: 900px;">
    <div class="from-froup user-item" *ngFor="let member of members">
      <input type="text" class="form-control control-filled" [value]="member.fname + ' ' + member.lname" readonly>
      <div class="select filled">
        <select #permissionDropdown class="form-control control-filled" [ngModel]="member.role" name="role" (ngModelChange)="permissionChanged($event, member, prevRole, permissionDropdown)"
        (focus)="prevRole=member.role">
          <option *ngFor="let role of permissions" [value]="role.val">{{role.text}}</option>
        </select>
        <i class="fa fa-angle-down"></i>
      </div>
      <button class="btn btn-success" [disabled]="user.current._id == member._id" (click)="removeMember(member)">Remove</button>
    </div>
  </div>
</div>
