<div class="form-column-header">
    <label class="column-label">Stage</label>
    <button class="btn btn-info btn-small" (click)="playbook.addStage()">+</button>
  </div>
  <div class="form-group form-column">

    <div class="form-items" style="border-right:solid 1px #dedede;">
      <div class="stages-item"[ngClass]="{'active': playbook.selectedStage == stage}" *ngFor="let stage of playbook.model.stages; let i = index">
        <input type="text" class="form-control" [(ngModel)]="stage.value" (click)="selectStage(stage)" placeholder="Next Stage">
        <div class="btn-grey-wrap">
          <button class="btn btn-info btn-small" (click)="playbook.removeStage(stage)">-</button>
          <div class="actionmenu" [ngClass]="{'active': playbook.selectedStage.doReorder}" *ngIf="playbook.model.stages.length > 1">
            <div class="actionmenu__label" (click)="playbook.toggleReorderStage()">&uarr;&darr;</div>
            <ul class="actionmenu__list">
              <li class="actionmenu__item actionmenu__item--up" *ngIf="i !== 0" (click)="playbook.moveStageUp(stage)">Move Up</li>
              <li class="actionmenu__item actionmenu__item--dn" *ngIf="i !== (playbook.model.stages.length -1)" (click)="playbook.moveStageDown(stage)">Move Down</li>
              <li class="actionmenu__item actionmenu__item--cancel" (click)="playbook.closeReorderStages()">Cancel</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
