<section class="content settings-wrap container-fluid">
	<div class="row">
		<div class="sidebar sidebar-white col-xs-12 col-sm-12 col-md-3 col-lg-2">
			<div class="title with-border">
				<span>Company</span> Dashboard
			</div>
			<div class="sidebar-content">
				<button class="btn btn-primary btn-sidebar" (click)="createKit()">Create Kit <span>+</span></button>
				<button class="btn btn-primary btn-sidebar" (click)="createAsset()">Create Asset <span>+</span></button>
				<ul class="activity" >
					<li class="activity-title">{{user.current?.curCompany.name}} Activity</li>
					<li *ngFor="let activity of activities" (click)="activityClick(activity)">
						<div class="time">{{ activity?.created | amTimeAgo }}</div>
						{{ getActivityMessage(activity) }}
					</li>
				</ul>
			</div>
		</div>
		<div class="dashboard nopadding col-xs-12 col-sm-12 col-md-9 col-lg-10">
			<app-announcment></app-announcment>
			<div class="filters-wrap">
				<div class="left">
					<div *ngIf="hasKit">
						<a class="btn btn-primary" [routerLink]="['/kit/' + kit ]">Back to Kit</a>
					</div>
					<div class="assets-list">
						<div class="asset-item with-footer" *ngFor="let asset of assets" >
							<app-asset-entity [asset]="asset"></app-asset-entity>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
