<section class="content edit-playbook">
  <div class="content-nav">
    <input type="text" class="form-control title" [(ngModel)]="playbook.model.name" name="playbookName" placeholder="New Playbook Title">
    <!-- <div class="autosaving" *ngIf="saved">
      Saved
    </div> -->
    <div class="buttons">
      <button class="cq-button cq-button--secondary cq-button--small cq-button--inline" (click)="settings()">Settings</button>
      <button class="cq-button cq-button--secondary cq-button--small cq-button--inline" (click)="update()" *ngIf="!saving">Save Draft</button>
      <button class="cq-button cq-button--secondary cq-button--small cq-button--inline" *ngIf="saving">Auto-Saving...</button>

      <button class="cq-button cq-button--small cq-button--inline" (click)="publishDialog()">Publish</button>
    </div>
  </div>
  <div class="container-fluid" *ngIf="playbook && selectedStage">
    <div class="playbook-form">
      <div class="row flex-row">
        <div style="width:15%;">
          <app-playbook-stages></app-playbook-stages>
        </div>
        <app-playbook-stage-content *ngIf="playbook.selectedStage" style="width:25%;"></app-playbook-stage-content>
        <app-playbook-step-content *ngIf="playbook.selectedStep" style="width:60%;"></app-playbook-step-content>
      </div>
    </div>
  </div>
</section>
