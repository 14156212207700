<div *ngIf="user.current?.curCompany.active || !user.current">

    <header class="header">
        <nav class="navbar" role="navigation">
            <!-- Brand and toggle get grouped for better mobile display -->
            <div class="navbar-header">
                <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1">
                    <span class="sr-only">Toggle navigation</span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </button>
                <a href="#" class="logo"><img src="assets/images/logo.jpg" alt=""></a>
            </div>

            <!-- Collect the nav links, forms, and other content for toggling -->
            <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1" *ngIf="user.current">
                <ul class="nav navbar-nav">
                    <li><a [routerLink]="['']">Dashboard</a></li>
                    <li><a [routerLink]="['playbook/list']">Playbooks</a></li>
                    <!--<li class="dropdown">
                        <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                            Playbooks
                            <i class="fa fa-angle-down"></i>
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
                            <li><a (click)="createPlaybook()">Create</a></li>
                            <li><a [routerLink]="['playbook/list']">List</a></li>
                        </ul>
                    </li>-->
                    <li><a [routerLink]="['/team']">Team</a></li>
                </ul>
                <div class="col-sm-3 col-md-3 col-lg-2 col-md-2 search-container">
                    <app-search></app-search>
                </div>
                <ul class="nav navbar-nav navbar-right">
                    <li class="dropdown">
                        <a href="#" class="dropdown-toggle" data-toggle="dropdown" *ngIf="user">
                            {{user.current?.fname}}&nbsp;{{user.current?.lname}}
                            <i class="fa fa-angle-down"></i>
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
                            <li><a [routerLink]="['/user/' + user.current?._id]">My Profile</a></li>
                            <li><a [routerLink]="['/settings']">Settings</a></li>
                            <li role="separator" class="divider"></li>
                            <li><a href="javascript:void(0)" (click)="logout()">Logout</a></li>
                        </ul>
                    </li>
                </ul>
            </div>

            <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1" *ngIf="!user.current">
                <ul class="nav navbar-nav">
                    <li><a (click)="goTo(0)">CloseQuickly</a></li>
                </ul>
                <ul class="nav navbar-nav navbar-right">
                    <li class="dropdown"><a (click)="goTo(0)" class="dropdown-toggle">Sign In</a></li>
                    <li class="dropdown"><a (click)="goTo(1)" class="dropdown-toggle">Sign Up</a></li>
                </ul>
            </div>
            <!-- /.navbar-collapse -->
        </nav>
    </header>
    <router-outlet></router-outlet>
    <app-toast></app-toast>
</div>
<div *ngIf="!user.current?.curCompany.active && !loadingUser" class="deleted-container">
    <div class="title">Your company has been deleted</div>
    <div *ngIf="user.current?.role == 'owner'" class="restore">
        Press here to restore.
        <button class="btn btn-default" (click)="logout()">Return to login</button>
        <button class="btn btn-primary" (click)="restore()">Restore</button>
    </div>
    <div *ngIf="user.current?.role != 'owner'" class="restore">
        Contact company owner.
        <button class="btn btn-primary" (click)="logout()">Return to login</button>
    </div>
</div>