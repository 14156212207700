<div class="grid">
  <div class="grid-item" *ngFor="let asset of data">
    <app-asset-entity [asset]="asset"></app-asset-entity>
  </div>
  <div class="more-wrap" *ngIf="totalCount && totalCount > limit">
    <div class="btn-group"  >
      <button class="btn btn-primary" title="Show previous {{itemsName}}" (click)="backward()" [disabled]="!skip">
        <
      </button>
      <button class="btn btn-primary" title="Show next {{itemsName}}" (click)="forward()" [disabled]="totalCount <= ( limit + skip )">
        >
      </button>
    </div>
  </div>
</div>

