<section class="content settings-wrap container-fluid">
	<div class="row">
		<div class="sidebar sidebar-white col-xs-12 col-sm-12 col-md-4 col-lg-2">
			<div class="title with-border">
				<span>View</span> Playbooks
			</div>
			<div class="sidebar-content">
				<button class="btn btn-primary btn-sidebar text-center" (click)="createPlaybook()">Create Playbook</button>
			</div>
		</div>
		<div class="dashboard nopadding col-xs-12 col-sm-12 col-md-8 col-lg-10">
			<div class="filters-wrap">
				<div class="left">
					<div *ngIf="hasKit">
						<a class="btn btn-primary" [routerLink]="['/kit/' + kit ]">Back to Kit</a>
					</div>
					<div class="playbooks-list">
						<div class="playbook-entity" *ngFor="let playbook of playbooks">
							<app-playbook-entity [playbook]="playbook"></app-playbook-entity>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
