<div class="col-lg-7">
  <h3>
    <a title="View Profile" [routerLink]="['/user/' + user.current?._id ]">
    Profile
  </a>
  </h3>
  <form [formGroup]="form" novalidate #f="ngForm" (submit)="update(f)" *ngIf="form">
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <label>Avatar</label>
          <div class="file file-img" [hidden]="user.current?.avatar != 'null' || avatarPreview != 'null'">
            <input type="file" id="pic" #fileInput (change)="fileSelected($event)">
            <label for="pic"><span>+</span></label>
          </div>
          <div class="img-wrap" [hidden]="avatarPreview == 'null'" (click)="fileInput.click()">
            <img [src]="avatarPreview" alt="">
          </div>
        </div>
      </div>
      <div class="col-sm-8">
        <div class="form-group" [ngClass]="{'has-error': !form.controls.fname.valid && f.submitted}">
          <label>First Name</label>
          <input type="text" class="form-control" formControlName="fname">
          <span class="help-block" *ngIf="form.controls.fname.errors?.required && f.submitted">Cannot be empty</span>
          <span class="help-block" *ngIf="form.controls.fname.errors?.maxlength && f.submitted">Cannot be longer than 35 symbols</span>
        </div>
        <div class="form-group" [ngClass]="{'has-error': !form.controls.lname.valid && f.submitted}">
          <label>Last name</label>
          <input type="text" class="form-control" formControlName="lname">
          <span class="help-block" *ngIf="form.controls.lname.errors?.required && f.submitted">Cannot be empty</span>
          <span class="help-block" *ngIf="form.controls.lname.errors?.maxlength && f.submitted">Cannot be longer than 35 symbols</span>
        </div>
        <div class="form-group" [ngClass]="{'has-error': !form.controls.description.valid && f.submitted}">
          <label>Brief description</label>
          <textarea class="form-control" formControlName="description"></textarea>
          <span class="help-block" *ngIf="form.controls.description.errors?.maxlength && f.submitted">Cannot be longer than 1024 symbols</span>
        </div>
        <div class="form-group">
          <label>Role</label>
          <input type="text" class="form-control" value="{{user.current?.role}}" readonly>
        </div>
        <div class="form-group" [ngClass]="{'has-error': !form.controls.email.valid && f.submitted}">
          <label>Email Address</label>
          <input type="email" class="form-control" formControlName="email">
          <span class="help-block" *ngIf="form.controls.email.errors?.required && f.submitted">Cannot be empty</span>
          <span class="help-block" *ngIf="form.controls.email.errors?.email && f.submitted">Must be a valid email</span>
        </div>
        <div class="form-group" [ngClass]="{'has-error': !form.controls.email.valid && f.submitted}">
          <label>Occupation</label>
          <input type="text" class="form-control" formControlName="occupation">
          <span class="help-block" *ngIf="form.controls.occupation.errors?.required && f.submitted">Cannot be empty</span>
          <span class="help-block" *ngIf="form.controls.occupation.errors?.occupation && f.submitted">Cannot be longer than 1024 symbols</span>
        </div>
        <div class="form-group" [ngClass]="{'has-error': !form.controls.email.valid && f.submitted}">
          <label>Regions</label>
          <input type="text" class="form-control" formControlName="regions">
          <span class="help-block" *ngIf="form.controls.regions.errors?.required && f.submitted">Cannot be empty</span>
          <span class="help-block" *ngIf="form.controls.regions.errors?.regions && f.submitted">Cannot be longer than 1024 symbols</span>
        </div>
        <div class="divider"></div>
        <div class="form-group" [ngClass]="{'has-error': !form.controls.password.valid && f.submitted}">
          <label>Password</label>
          <input type="password" class="form-control" formControlName="password">
          <span class="help-block" *ngIf="form.controls.password.errors?.minlength && f.submitted">Cannot be less than 6 symbols</span>
        </div>
        <div class="form-group" [ngClass]="{'has-error': !form.controls.passRep.valid && f.submitted}">
          <label>Confirm Password</label>
          <input type="password" class="form-control" formControlName="passRep" pattern="{{form.controls.password.value}}">
          <span class="help-block" *ngIf="form.controls.passRep.errors?.pattern && f.submitted">Passwords don't match</span>
        </div>
      </div>
    </div>
    <div class="text-right">
      <button class="btn btn-primary" type="submit">Save Changes</button>
    </div>
  </form>
</div>