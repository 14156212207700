<div class="modal-body">
  <div class="title">Link To Asset</div>
  <p>Use the field below to link your playbook to external assets such as videos, websites or blog posts.</p>
  <div class="form-group" [ngClass]="{'has-error': linkError}">
    <label for="URL"></label>
    <input type="text" class="form-control control-dashed" [(ngModel)]="link">
    <p class="help-block">{{linkError}}</p>
  </div>
  <div class="btns-block">
    <button class="btn btn-primary" (click)="createAsset()" [disabled]="!link">Next</button>
    <button class="btn btn-default" md-dialog-close>Cancel</button>
  </div>
</div>