<div class="title">
  Upload Asset</div>
<p>Uploading {{getUploadedCount()}} of {{files.length}} files. Please wait.</p>
<div class="uploading" style="width: 550px;">
  <div class="uploading-item" *ngFor="let file of files">
    <div class="progress" [ngClass]="{'progress-succsess': file.percent == 100, 'progress-warning': file.percent < 100}">
      <div class="progress-bar" role="progressbar" [style.width.%]="file.percent">
        <span class="sr-only">{{file.percent}}% Complete</span>
      </div>
    </div>
    <div class="file-name">{{file.file.name}}</div>
    <div class="total" [ngClass]="{'total-success': file.percent >= 100}"></div>
  </div>
</div>
<div class="text-right">
  <button class="btn btn-default" md-dialog-close>Cancel</button>
  <button class="btn btn-success" (click)="startUpload()" *ngIf="getUploadedCount() < files.length">Start Upload</button>
  <button class="btn btn-success" [md-dialog-close]="assets" *ngIf="getUploadedCount() == files.length">Next</button>
</div>