<div class="notFound" *ngIf="notFound">
	<span>404</span>
	<p>Playbook not found</p>
</div>
<section class="content playbook-view container-fluid hidden-sm hidden-xs" *ngIf="!notFound">
	<div class="content-nav">
		<div class="title">{{playbook?.name}}</div>
	</div>
	<div class="row">
		<div class="settings-wrap col-12">
			<div class="sidebar sidebar-white sidebar-playbook" style="width:15%;">
				<div class="title nodivider">
					<span>Stage</span>
				</div>
				<div class="sidebar-content">
					<ul class="nav playbook-nav">
						<li [ngClass]="{'active': selectedStage == stage}" *ngFor="let stage of playbook?.stages" 
							(click)="selectedStage = stage; selectedStep = stage.steps[0]">
							<a href="javascript:void(0)">
								<span class="total">{{(100 / playbook?.stages.length) * (playbook?.stages.indexOf(stage) + 1) | round}}%</span>
								<span>{{stage.value}}</span>
								<!--<span class="progress-wrap">
									<span class="circular-progress-wrap">
										<span class="circular-progress"></span>
								</span>
								</span>-->
							</a>
						</li>
					</ul>
				</div>
			</div>
			<div class="pods-wrap" style="width:25%;">
                <div class="title">
                    <span>Stage Steps</span>
                </div>
				<ol class="nav">
					<li [ngClass]="{'active': selectedStep == step}" *ngFor="let step of selectedStage?.steps" (click)="selectedStep = step">
						<a href="javascript:void(0)">
							<span>{{step.value}}</span>
							<!--<span class="progress-wrap">
								<span class="circular-progress-wrap">
									<span class="circular-progress"></span>
								</span>
							</span>-->
						</a>
					</li>
				</ol>
			</div>
			<div class="guidance-wrap" style="width:60%;">
				<div class="content-column">
					<div>
						<div class="title">
							<span>Guidance</span>
						</div>
						<div [innerHTML]="(selectedStep?.content || '') | trustHTML">
						</div>
					</div>
					<div class="tebs">
						<!-- Nav tabs -->
						<ul class="nav nav-tabs" role="tablist">
							<li role="presentation" class="active"><a href="#resources" aria-controls="resources" role="tab" data-toggle="tab">Resources ({{selectedStep?.assets.length}})</a></li>
							<li role="presentation"><a href="#questions" aria-controls="questions" role="tab" data-toggle="tab"> Questions To Ask ({{selectedStep?.questions.length}})</a></li>
							<li role="presentation"><a href="#tasks" aria-controls="tasks" role="tab" data-toggle="tab">Tasks ({{selectedStep?.tasks.length}})</a></li>
						</ul>
						<!-- Tab panes -->
						<div class="tab-content">
							<div role="tabpanel" class="tab-pane active" id="resources">
								<div class="resources-list">
									<div class="resource-item" *ngFor="let asset of selectedStep?.assets">
										<app-asset-entity [asset]="asset" *ngIf="asset"></app-asset-entity>
									</div>
								</div>
							</div>
							<div role="tabpanel" class="tab-pane" id="questions">
								<ol class="questions-list">
									<li class="question-item" *ngFor="let q of selectedStep?.questions">
										{{q.value}}
										<form>
											<label>Answer</label>
											<div class="form-group">
												<textarea class="form-control control-dashed"></textarea>
											</div>
										</form>
									</li>
								</ol>
							</div>
							<div role="tabpanel" class="tab-pane" id="tasks">
								<div class="tasks-list">
									<div class="task-item" (click)="task.checked = !task.checked" *ngFor="let task of selectedStep?.tasks">
										<input type="checkbox" [ngModel]="task.checked">
										<label>{{task.value}}</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<section class="content mobile playbook-view container-fluid hidden-md hidden-lg hidden-xlg">
	<div class="title">{{playbook?.name}}</div>
	<div class="stages">
		<div class="stage" *ngFor="let stage of playbook?.stages">
			<div class="header">
				<div class="total">{{(100 / playbook?.stages.length) * (playbook?.stages.indexOf(stage) + 1) | round}}% </div>
				<div class="stage-title">{{stage.value}}</div>
				<i class="material-icons pull-right" [ngClass]="{'reverse': stage.selected}" (click)="stage.selected = !stage.selected">keyboard_arrow_down</i>
			</div>
			<div class="content" [ngClass]="{'collapsed': !stage.selected}" *ngIf="stage.steps.length > 0">
				<div class="step-title">{{stage.selectedStep.value}}</div>
				<div class="html-embed" [innerHTML]="(stage.selectedStep.content || '') | trustHTML"></div>
			</div>
			<div class="content empty"  [ngClass]="{'collapsed': !stage.selected}" *ngIf="stage.steps.length == 0">
				<div class="error-hint">No content</div>
			</div>
			<div class="stage-controls" *ngIf="stage.steps.length > 0" [ngClass]="{'hidden': !stage.selected}">
				<i class="material-icons" (click)="prevStep(stage)">keyboard_arrow_left</i>
				<span>{{stage.steps.indexOf(stage.selectedStep) + 1}} of {{stage.steps.length}}</span>
				<i class="material-icons" (click)="nextStep(stage)">keyboard_arrow_right</i>
			</div>
			<div class="step-assets" *ngIf="stage.steps.length > 0" [ngClass]="{'hidden': !stage.selected}">
				<div class="item" (click)="stage.selectedStep.showAssets = !stage.selectedStep.showAssets; stage.selectedStep.showQa = false; stage.selectedStep.showTasks = false"
					[ngClass]="{'active': stage.selectedStep.showAssets}">
					Assets ({{stage.selectedStep.assets.length}})
				</div>
				<div class="item" (click)="stage.selectedStep.showQa = !stage.selectedStep.showQa; stage.selectedStep.showAssets = false; stage.selectedStep.showTasks = false"
					[ngClass]="{'active': stage.selectedStep.showQa}">
					Questions ({{stage.selectedStep.questions.length}})
				</div>
				<div class="item" (click)="stage.selectedStep.showTasks = !stage.selectedStep.showTasks; stage.selectedStep.showAssets = false; stage.selectedStep.showQa = false"
					[ngClass]="{'active': stage.selectedStep.showTasks}">
					Tasks ({{stage.selectedStep.tasks.length}})
				</div>
				<div class="item-shown" *ngIf="stage.selectedStep.showAssets">
					<div class="item-title">Assets</div>
					<div class="item-close"><i (click)="stage.selectedStep.showAssets = false" class="material-icons">close</i></div>
					<div class="assets-list">
						<div class="asset" *ngFor="let asset of stage.selectedStep.assets">
							<div class="asset-title">{{asset.name}}</div>
							<div class="asset-type">{{asset.path.split('.')[asset.path.split('.').length - 1]}}</div>
						</div>
					</div>
				</div>
				<div class="item-shown" *ngIf="stage.selectedStep.showQa">
					<div class="item-title">Questions</div>
					<div class="item-close"><i (click)="stage.selectedStep.showQa = false" class="material-icons">close</i></div>
					<div class="qa-list">
						<div class="question" *ngFor="let q of stage.selectedStep.questions">"{{q.value}}"</div>
					</div>
				</div>
				<div class="item-shown" *ngIf="stage.selectedStep.showTasks">
					<div class="item-title">Tasks</div>
					<div class="item-close"><i (click)="stage.selectedStep.showTasks = false" class="material-icons">close</i></div>
					<div class="tasks-list">
						<div class="task-item" *ngFor="let task of selectedStep?.tasks" (click)="task.checked = !task.checked" >
							<input type="checkbox" [ngModel]="task.checked">
							<label>{{task.value}}</label>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>