<div class="modal-body">
  <div class="mat-dialog-title">Playbook Settings</div>
  <div class="form-group">
    <label>Name:</label>
    <input class="form-control" type="text" name="name" [(ngModel)]="playbook.name">
  </div>

  <div class="form-group">
    <label>Description:</label>
    <textarea class="form-control" name="description" [(ngModel)]="playbook.description"></textarea>
  </div>

  <div class="form-group">
    <div>
      <label>Tags:</label>
      <span *ngFor="let t of tag" class="tags">
        {{t.value}}
                         <a (click)="removeTag(t)">
                            <i class="fa fa-close"></i>
                        </a>
      </span>
      <span data-title="Add tag" role="button" class="cq-button cq-button--more" (click)="toggleTagAtoComplete()">
      ...
      </span>
    </div>
    <div *ngIf="showTagAC">
      <tag-input [(ngModel)]="tag"
                 (onSelect)="onSelect($event)"
                 name="tags"
                 (onTextChange)="getTags($event)"
      >
        <tag-input-dropdown
                [autocompleteItems]="tags"
                [appendToBody]="false"
                [focusFirstElement]="false"
        >
        </tag-input-dropdown>
      </tag-input>
    </div>
  </div>

  <div class="form-group">
    <label>Status</label>
    <div>
      <span class="cq-radio-item">Draft <input [checked]="!playbook?.publish" type="radio" [value]="0" name="playbook.publish" [(ngModel)]="playbook.publish"></span>
      <span class="cq-radio-item">Published <input [checked]="!!playbook?.publish " type="radio" [value]="1" name="playbook.publish" [(ngModel)]="playbook.publish"></span>
    </div>
  </div>

  <div class="form-group">
    <label>Authors/Contributors</label>
    <span class="alert alert-success members" *ngFor="let user of playbook?.collaborators">
      {{ user && user.fname && user.lname ? user.fname + ' ' + user.lname.substr(0,1) + '.' : ''}}
       <a (click)="removeMember(user)">
         <i class="fa fa-close"></i>
       </a>
     </span>
    <span role="button" class="cq-button cq-button--more" (click)="toggleUserAtoComplete()">
      ...
    </span>

    <div class="user-auto-complete" *ngIf="showUserAC">
      <input
              [(ngModel)]="memberEmail"
              (change)="getUsers()"
              (keyup)="getUsers()"
              class="form-control"
              name="users">
      <div class="member-candidates" *ngIf="users.length && memberEmail">
                             <span *ngFor="let member of users" (click)="onSelectMember(member)">
                               {{member.email}}
                             </span>
      </div>

    </div>
  </div>

  <div class="form-group small-buttons">
    <label>Actions</label>
    <button class="btn btn-default " (click)="delete()">Delete playbook</button>
    <button class="btn btn-default " (click)="clone()">Save a copy</button>
  </div>
  <div class="text-center">
    <button class="cq-button cq-button--inline cq-button--cancel" (click)="close()">Cancel</button>
    <button class="cq-button cq-button--inline" (click)="apply()">Apply</button>
  </div>

</div>
