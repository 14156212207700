<div class="container-fluid col-lg-9 app-stripe">
  <div class="row">
    <div class="col-xs-12 col-sm-6">
      <h2>Plan / Billing</h2>
      <div class="divider"></div>
      <span class="billing-title">Your subscription</span>
      <div class="your-plan" (click)="viewPlans()">
        <h3>You are on the <span>{{plan?.name}}</span> Plan</h3>
        <p>${{plan?.amount / 100}} Billed per month on card number ending in {{customer?.sources.data[0].last4}}</p>
        <p>Click here to view our plans</p>
      </div>
      <div class="buttons-group">
        <button class="btn btn-default" (click)="cancelPlan()" *ngIf="plan?.name != 'Free'" [disabled]="updating">Cancel plan</button>
        <button class="btn btn-default" (click)="delCompany()" *ngIf="plan?.name == 'Free'" [disabled]="updating">Delete Company</button>
        <button class="btn btn-primary" [routerLink]="['./plans']" [disabled]="updating">Change plan</button>
      </div>
      <div class="divider"></div>
      <span class="billing-title">Billing history</span>
      <table class="table table-striped" *ngIf="history.items.length">
        <thead>
          <tr>
            <th>Date</th>
            <th>Description</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tr *ngFor="let item of history.items">
          <td>{{ getDate(item.date)}}</td>
          <td>{{item.lines.data[0].plan.statement_descriptor}}</td>
          <td>${{getAmount(item.amount_due)}}</td>
        </tr>
      </table>

      <div class="more-wrap" *ngIf="history.items.length && history.items.length > limit">
        <div class="btn-group">
          <button class="btn btn-primary" title="Show previous lines" (click)="backward()" [disabled]="!skip">
            <
          </button>
          <button class="btn btn-primary" title="Show next lines" (click)="forward()" [disabled]="history.items.length < history.limit + history.skip">
            >
          </button>
        </div>
      </div>

    </div>
    <div class="col-xs-12 col-sm-6">
      <h2>Update credit card</h2>
      <div class="divider"></div>
      <span class="billing-title">Card info</span>
      <div class="row">
        <div class="col-xs-12">
          <label>Card #</label>
          <input required="true" [(ngModel)]="cardData.number" id="card-number" type="text" name="card.number" class="form-control">
          <p class="error-hint" *ngIf="cardValidation.number.error">{{cardValidation.number.message}}</p>
        </div>
        <div class="col-xs-6">
          <label>Expiry (MM/YY)</label>
          <input required="true" [(ngModel)]="cardData.expiry" id="card-expiry" type="text" name="card.expiry" class="form-control">
          <p class="error-hint" *ngIf="cardValidation.exp_year.error">{{cardValidation['exp_year'].message}}</p>
          <p class="error-hint" *ngIf="cardValidation.exp_month.error">{{cardValidation['exp_month'].message}}</p>
        </div>
        <div class="col-xs-6">
          <label>CVC</label>
          <input required="true" [(ngModel)]="cardData.cvv" id="card-cvc" type="text" name="card.cvc" class="form-control">
        </div>
      </div>
      <div class="row divider-group">
        <div class="col-xs-12">
          <div class="divider"></div>
          <span class="billing-title">Billing details</span>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <label>First Name</label>
          <input required="true" type="text" [(ngModel)]="cardData.person.fname" name="card.person.fname" class="form-control">
        </div>
        <div class="col-xs-6">
          <label>Last Name</label>
          <input required="true" type="text" name="cardData.person.lname" [(ngModel)]="cardData.person.lname" class="form-control">
        </div>
        <div class="col-xs-12">
          <label>Address line 1</label>
          <input required="true" type="text" name="cardData.person.al1" [(ngModel)]="cardData.person.al1" class="form-control">
        </div>
        <div class="col-xs-12">
          <label>Address line 2</label>
          <input type="text" name="cardData.person.al2" class="form-control" [(ngModel)]="cardData.person.al2">
        </div>
        <div class="col-xs-12">
          <label>Country</label>
          <input required="true" type="text" name="cardData.person.country" class="form-control" [(ngModel)]="cardData.person.country">
        </div>
        <div class="col-xs-6">
          <label>City</label>
          <input required="true" type="text" name="cardData.person.city" class="form-control" [(ngModel)]="cardData.person.city">
        </div>
        <div class="col-xs-6">
          <label>Zip code</label>
          <input required="true" type="text" name="cardData.person.zip" class="form-control" [(ngModel)]="cardData.person.zip">
        </div>
        <div class="col-xs-12">
          <label>Phone number</label>
          <input type="text" name="cardData.person.phone" class="form-control" [(ngModel)]="cardData.person.phone">
        </div>
      </div>
      <div class="row text-right" style="margin-bottom:25px;">
        <div class="col-xs-12">
          <button class="btn btn-primary" (click)="updateCard()" [disabled]="updating">Update card</button>
        </div>
      </div>
    </div>
  </div>
</div>