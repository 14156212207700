<div class="more-wrap" *ngIf="totalCount && totalCount > baseCount">
	<div class="btn-group"  >
		<button class="btn btn-primary" title="Show previous {{types[type]}}" (click)="backward()" [disabled]="!skip">
			<
		</button>
		<button class="btn btn-primary" title="Show next {{types[type]}}" (click)="forward()" [disabled]="totalCount <= ( baseCount + skip )">
			>
		</button>
	</div>
</div>
