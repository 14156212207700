<div class="text-center">
  <h4>{{message}}</h4>
  <div class="text-center">
    <a class="btn btn-default" (click)="close()">
      Close
    </a>
    <a *ngIf="link.url" (click)="go()" class="btn btn-primary">
      {{link.name}}
    </a>
  </div>
</div>
