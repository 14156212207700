<div class="container search-top-bar">
    <div class="row">
        <div class="title col-sm-4">
            <span  *ngIf="!!search">Searched for:</span>
            <span  *ngIf="!!search" class="search-for"> &laquo;{{search}}&raquo;</span>
        </div>
        <div class="tags-wrap col-sm-8" *ngIf="searchResults.data.tags.length">
            <label>Tags</label>
            <div class="tags-list">
                <app-active-tag [value]="tag" *ngFor="let tag of searchResults.data.tags"></app-active-tag>
            </div>
        </div>
    </div>
</div>
<div class="divider"></div>
<div class="container">
    <div class="total-search-wrap row">
        <div class="sidebar-new col-sm-4">
            <span>Categories to show</span>

            <div class="radio" >
                <input (change)="displayAllCategories()" type="radio" name="categories" id="cat1"
                >
                <label for="cat1">All</label>
            </div>
            <div [attr.class]="searchResults.data.assets.length ? 'radio' : 'radio disabled' " >
                <input (change)="switchCategoryVisiability('assets')" type="radio" name="categories" id="cat2"
                       [disabled]="!searchResults.data.assets.length"
                >
                <label for="cat2">Assets</label>
            </div>
            <div [attr.class]="searchResults.data.playbooks.length ? 'radio' : 'radio disabled' " >
                <input (change)="switchCategoryVisiability('playbooks')" type="radio" name="categories" id="cat3"
                       [disabled]="!searchResults.data.playbooks.length"
                >
                <label for="cat3">Playbooks</label>
            </div>
            <div [attr.class]="searchResults.data.kits.length ? 'radio' : 'radio disabled'">
                <input (change)="switchCategoryVisiability('kits')" type="radio" name="categories" id="cat4"
                       [disabled]="!searchResults.data.kits.length">
                <label for="cat4">Kits</label>
            </div>
            <div [attr.class]="searchResults.data.users.length ? 'radio' : 'radio disabled'">
                <input (change)="switchCategoryVisiability('users')" type="radio" name="categories" id="cat5"
                       [disabled]="!searchResults.data.users.length">
                <label for="cat5">Team</label>
            </div>
        </div>
        <div class="search-content col-sm-8">
            <div [attr.class]="(categoryVisiability.assets&&!!searchResults.data.assets.length)||paginationMode.assets ? 'search-block' : 'search-block hidden'">
                <div class="title">
                    Assets
                    <span>Showing {{ searchResults.data.assets.length }} of {{searchResults.data.totalCounts.assets}} results</span>
                </div>
                <div class="grid">
                    <div class="grid-item asset-item with-footer" *ngFor="let asset of searchResults.data.assets; let i = index;">
                        <app-asset-entity [asset]="asset" ></app-asset-entity>
                    </div>
                </div>
                <app-pagination
                        [type]="2"
                        [baseCount]="searchResults.data.assets.length"
                        [q]="search"
                        [totalCount]="searchResults.data.totalCounts.assets"
                ></app-pagination>
            </div>
            <div [attr.class]="categoryVisiability.playbooks&&!!searchResults.data.playbooks.length? 'search-block' : 'search-block hidden'">
                <div class="title">
                    Playbooks
                    <span>Showing {{searchResults.data.playbooks.length}} results of {{searchResults.data.totalCounts.playbooks}}</span>
                </div>
                <div class="grid">
                    <div class="grid-item playbook-item with-footer" *ngFor="let playbook of searchResults.data.playbooks; let i = index;">
                        <app-playbook-entity class="playbook-item" [playbook]="playbook" ></app-playbook-entity>
                    </div>
                </div>
                <app-pagination
                        [type]="0"
                        [baseCount]="searchResults.data.playbooks.length"
                        [q]="search"
                        [totalCount]="searchResults.data.totalCounts.playbooks"
                ></app-pagination>
            </div>
            <div [attr.class]="categoryVisiability.kits&&!!searchResults.data.kits.length ? 'search-block' : 'search-block hidden'">
                <div class="title">
                    Kits
                    <span>Showing {{searchResults.data.kits.length}} results of {{searchResults.data.totalCounts.kits}}</span>
                </div>
                <div class="grid">
                    <div class="grid-item kit" *ngFor="let kit of searchResults.data.kits; let i = index;">
                        <app-kit-entity [kit]="kit" [user]="user.current"></app-kit-entity>
                    </div>
                </div>
                <app-pagination
                        [type]="3"
                        [baseCount]="searchResults.data.kits.length"
                        [q]="search"
                        [totalCount]="searchResults.data.totalCounts.kits"
                ></app-pagination>
            </div>
            <div [attr.class]="categoryVisiability.users&&!!searchResults.data.users.length ? 'search-block' : 'search-block hidden'">
                <div class="title">
                    Team
                    <span>Showing {{searchResults.data.users.length}} results of {{searchResults.data.totalCounts.users}}</span>
                </div>
                <div class="assets-list">
                    <div class="asset-item with-footer team-item" *ngFor="let user of searchResults.data.users; let i = index;">
                        <app-user-entity [user]="user"></app-user-entity>
                    </div>
                    <app-pagination
                            [type]="1"
                            [baseCount]="searchResults.data.users.length"
                            [q]="search"
                            [totalCount]="searchResults.data.totalCounts.users"
                    ></app-pagination>
                </div>
            </div>
        </div>
    </div>
</div>

