<div class="row">

  <div class="col-xs-12 col-sm-12">
    <h2>Downgrade plan</h2>
    <p>The features of this plan are limited - please set your preference bellow</p>
  </div>
  <div class="col-xs-12 col-sm-6">
    <h3>PLaybooks <span>(maximum: {{plan?.metadata ? plan?.metadata.playbooks : 'unlimited'}})</span></h3>
    <ul class="list-unstyled downgrade-items">
      <li class="{{isNotChecked('playbooks', playbook._id)  ? 'active' : ''}}" *ngFor="let playbook of data?.playbooks" (click)="choose('playbooks', playbook._id)">
        {{getPlaybookName(playbook.name)}} <i class="fa fa-{{isNotChecked('playbooks', playbook._id ) ? 'check-' : ''}}circle-o"></i>
      </li>
    </ul>

  </div>
  <div class="col-xs-12 col-sm-6">
    <h3>Users <span>(maximum: {{plan?.metadata ? plan?.metadata .users : 'unlimited'}})</span></h3>
    <ul class="list-unstyled downgrade-items">
      <li class="{{isNotChecked('users', user._id)  ? 'active' : ''}}" *ngFor="let user of data?.users" (click)="choose('users', user._id)" >
        {{user.fname + ' ' + user.lname}} <i class="fa fa-{{isNotChecked('users', user._id)  ? 'check-' : ''}}circle-o"></i>
      </li>
    </ul>
  </div>
  <div class="col-xs-12 col-sm-12" style="margin-bottom:25px">
    <button [disabled]="!canNotDowngrade" class="btn btn-primary" (click)="downgrade(plan.id)">Downgrade</button>
  </div>
</div>