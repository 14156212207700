<div class="app-item">
  <div class="playbook-body">
    <div [routerLink]="['/kit', kit._id]">
      <div class="name" [innerHtml]="smartName(kit.name)"></div>
      <p> by {{getCreatorName()}}</p>
      <div class="date">
        {{kit.created | date:'MMM dd yyyy' }}
      </div>
    </div>
  </div>
  <div class="grid-item-footer">
    <app-like
            [type]="'kit'"
            [likesCount]="kit.likes.length"
            [isLiked]=" kit.likes.length && (kit.likes.indexOf(authUser.current?._id) >= 0)"
            [id]="kit._id">
    </app-like>
    <app-follow-kit *ngIf="!isMy" [userId]="authUser.current?._id" [kit]="kit"></app-follow-kit>
    <a href="#"><i class="material-icons">share</i></a>
    <a role="button" *ngIf="isMy" (click)="toggleActionBar()"><i class="material-icons">more_vert</i></a>
  </div>
  <div class="action-bar" *ngIf="showActionBar">
    <ul class="list-unstyled">
      <li>
        <a (click)="edit()">Edit</a>
      </li>
      <li>
        <a (click)="delete()">Delete</a>
      </li>
    </ul>
  </div>
</div>