<div class="row" *ngIf="loaded">
  <div class="plans-header">
    <div class="main">Start Closing Now</div>
    <div class="submain">Unleash the full potential of your team</div>
  </div>
  <div class="plan-list">
    <div class="plan" *ngFor="let plan of plans">
      <div class="wrapper">
        <div class="main-container" [style.background-color]="plan.metadata.bck">
          <div class="title">{{plan.name}}</div>
          <div class="price"><span>&#36;</span>{{plan.amount / 100}}</div>
          <div class="interval">billed per {{plan.interval}}</div>
          <button class="btn btn-primary" [style.background-color]="plan.metadata.btn" *ngIf="plan.id != subscription?.plan.id" (click)="subscribe(plan)" [disabled]="plan.inProg">
            Select
          </button>
          <button class="btn btn-primary" [style.background-color]="plan.metadata.btn" *ngIf="plan.id == subscription?.plan.id">Current</button>
        </div>
        <div class="benefits" [style.background-color]="plan.metadata.ftr">
          <div class="benefit-item">
            <i class="material-icons">check</i>
            <span>{{plan.metadata.playbooks}} playbooks</span>
          </div>
          <div class="benefit-item">
            <i class="material-icons">check</i>
            <span>{{plan.metadata.users}} users</span>
          </div>
          <div class="benefit-item" *ngFor="let ben of plan.miscBen">
            <i class="material-icons">check</i>
            <span>{{ben}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="plan">
      <div class="wrapper">
        <div class="main-container" [style.background-color]="'#d1ecf3'">
          <div class="title">ENTERPRISE</div>
          <div class="placeholder"></div>
          <div class="interval">A plan tailored to your business' needs</div>
          <button class="btn btn-primary" [style.background-color]="'#00a0c2'">Schedule a demo</button>
        </div>
        <div class="benefits" [style.background-color]="'#c6e7f0'">
          <div class="benefit-item">
            <i class="material-icons">check</i>
            <span>Unlimited playbooks</span>
          </div>
          <div class="benefit-item">
            <i class="material-icons">check</i>
            <span>Unlimited users</span>
          </div>
          <div class="benefit-item">
            <i class="material-icons">check</i>
            <span>Custom consultation</span>
          </div>
          <div class="benefit-item">
            <i class="material-icons">check</i>
            <span>Dedicated support</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="loader" *ngIf="!loaded">
  <span>Fetching plans, please wait.</span>
  <img src="/assets/images/Spinner.gif" />
</div>
