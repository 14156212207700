<div class="file-viewer">
    <iframe #iframe class="ms-viewer" *ngIf="filetype == filetypes.MS" [attr.src]="safeUrl"></iframe>
    
    <iframe #iframe class="ms-viewer" *ngIf="filetype === filetypes.URL" [attr.src]="safeUrl2"></iframe>
    
    <iframe #iframe class="ms-viewer" *ngIf="filetype === filetypes.PDF" [attr.src]="safeUrl3"></iframe>

    <img class="image" *ngIf="filetype == filetypes.IMAGE" [attr.src]="safeUrl2">

    <video class="video" controls *ngIf="filetype == filetypes.VIDEO" [attr.src]="path"></video>

    <audio class="audio" controls volume *ngIf="filetype == filetypes.AUDIO" [attr.src]="path"></audio>

    <p *ngIf="filetype == filetypes.TXT">{{data?.content}}</p>

</div>
