<div class="modal-body" *ngIf="assets.length > 0">
  <div class="title text-left">Edit files details</div>
  <p>Choose file to edit this details</p>
  <div class="files-list">
    <div class="names">
      <div class="title-names">
        Filename
      </div>
      <ul class="nav" *ngFor="let asset of assets">
        <li [ngClass]="{'active': selected == assets.indexOf(asset)}" (click)="selected = assets.indexOf(asset)">
          <a href="javascript:void(0)" *ngIf="asset.filetype !== 5">{{asset.name}}</a>
          <a href="javascript:void(0)" *ngIf="asset.filetype === 5">{{asset.path}}</a>
        </li>
      </ul>
    </div>
    <div class="info" style="width: 550px; max-height: none;">
      <div class="form-group">
        <label>Thumbnail Image</label>
        <div class="file file-pic">
          <input type="file" id="pic" accept="image/*" (change)="previewSelected(assets[selected], $event)" #fileinput>
          <label for="pic" *ngIf="!assets[selected].previewThumb">- no image -</label>
          <img (click)="fileinput.click()" *ngIf="assets[selected].previewThumb" [src]="assets[selected].previewThumb" style="display:block; width: 188px;height: 130px;"
          />
        </div>
      </div>
      <div class="form-group">
        <label>Name</label>
        <input type="text" class="form-control control-dashed" [(ngModel)]="assets[selected].name">
      </div>
      <div class="form-group">
        <tag-input [(ngModel)]="assets[selected].tags" [onAdding]="onAdding" [onRemoving]="onRemoving">
          <tag-input-dropdown [autocompleteObservable]="getTags">
          </tag-input-dropdown>
        </tag-input>
      </div>
      <div class="form-group">
        <label>Description</label>
        <textarea [(ngModel)]="assets[selected].description" class="form-control control-dashed"></textarea>
      </div>

    </div>
  </div>
  <div class="buttons">
    <div class="pull-left">
      <button class="btn btn-default" (click)="cancel()">Cancel</button>
    </div>
    <div class="pull-right">
      <button class="btn btn-primary" (click)="updateAssets()">All done</button>
    </div>
  </div>
</div>
