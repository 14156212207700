<div class="app-item">
  <div class="asset-body">
    <div class="user-pic"
         [ngStyle]="{'background-image': 'url(' + ( user.avatar || 'assets/images/noavatar.png') + ')'}"
         [routerLink]="['/user/', user._id]"
    ></div>
    <div class="name">{{user.fname}} {{user.lname}}</div>
  </div>
    <div class="asset-footer">
    <ul class="list-unstyled">
      <li>
        Title/Role: {{user.occupation}}
      </li>
      <li>
        Joined: {{ user.created | date:'MMM dd yyyy' }}
      </li>
      <li>
        Regions: {{ user.regions }}
      </li>
    </ul>
  </div>
</div>