<div class="search">
	<div class="from-group">
		<input
				autocomplete="off"
				(keyup)="autocomplete()"
				name="q"
				[(ngModel)]="q"
				type="text"
				class="form-control"
				placeholder="Search"
				(keyup.enter)="enter()"
				(focus)="showAC()"
				(blur)="hideAC()"
		>
		<i class="fa fa-search"></i>
	</div>
</div>
<div class="search-autoComplete" *ngIf="showAutoCompleteResults" (mouseover)="stopHide()" (mouseout)="startHide()">
	<div class="search-autoComplete-group" *ngIf="autoCompleteResults.data.users.length">
		<div class="search-autoComplete-group-header">
			Users ( {{autoCompleteResults.data.users.length}} found )
		</div>
		<div class="search-autoComplete-item" *ngFor="let user of autoCompleteResults.data.users;">
			<a [routerLink]="['./user', user._id]">{{ user.fname + '&nbsp;' + user.lname}}</a>
		</div>
	</div>
	<div class="search-autoComplete-group" *ngIf="autoCompleteResults.data.kits.length">
		<div class="search-autoComplete-group-header">
			Kits ( {{ autoCompleteResults.data.kits.length }} found )
		</div>
		<div class="search-autoComplete-item" *ngFor="let kit of autoCompleteResults.data.kits;">
			<a [routerLink]="['./kit', kit._id]">{{ smartName(kit.name) }}</a>
		</div>
	</div>
	<div class="search-autoComplete-group" *ngIf="autoCompleteResults.data.playbooks.length">
		<div class="search-autoComplete-group-header">
			Playbooks ( {{ autoCompleteResults.data.playbooks.length }} found )
		</div>
		<div class="search-autoComplete-item" *ngFor="let playbook of autoCompleteResults.data.playbooks;">
			<a  [routerLink]="['./playbook', 'view', playbook._id]">{{ smartName(playbook.name) }}</a>
		</div>
	</div>
	<div class="search-autoComplete-group" *ngIf=" autoCompleteResults.data.assets.length">
		<div class="search-autoComplete-group-header">
			Assets ( {{ autoCompleteResults.data.assets.length}} found )
		</div>
		<div class="search-autoComplete-item" *ngFor="let asset of autoCompleteResults.data.assets;">
			<a (click)="previewAsset(asset)">{{ smartName(asset.name) }}</a>
		</div>
	</div>
</div>